<!-- =========================================================================================
  File Name: UserView.vue
  Description: User View page
========================================================================================== -->

<template>
  <div id="page-user-view">
    <vs-alert color="danger" title="User Not Found" v-if="!accData">
      <span>Error acc not founded!!</span>
    </vs-alert>

    <div id="user-data" v-if="accData">
      <vx-card :title="accData.name" class="mb-base">
        <!-- Avatar -->
        <div class="vx-row">
          <!-- Avatar Col -->
          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <table>
              <tr>
                <td class="font-semibold">{{ $t("Name") }}</td>
                <td>{{ accData.name }}</td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("CompanyIndustry") }}</td>
                <td>{{ accData.companyIndustry }}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 1 -->

          <!-- Information - Col 2 -->
          <div class="vx-col flex-1" id="account-info-col-2">
            <table>
              <tr>
                <td class="font-semibold">{{ $t("Status") }}</td>
                <td>
                  {{ accData.status == 1 ? $t("Active") : $t("Inactive") }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("CreatedAt") }}</td>
                <td>{{ formatDate(accData.createdAt) }}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 2 -->
          <div class="vx-col w-full flex" id="account-manage-buttons">
            <vs-button
              v-if="accEditPopupActive"
              icon-pack="feather"
              icon="icon-edit"
              class="mr-4"
              size="small"
              @click="accEditPopupActive = true"
              >{{ $t("Edit") }}</vs-button
            >
            <vs-button
              v-if="popupCreateAccActive"
              class="mr-4"
              color="primary"
              type="border"
              icon-pack="feather"
              icon="icon-plus"
              size="small"
              @click="(popupCreateAccActive = true), (create = true)"
              >{{ $t("Create") }} {{ $t("Account") }}</vs-button
            >
          </div>
        </div>
      </vx-card>
      <vs-popup
        class="holamundo"
        :title="$t('Edit') + ' ' + $t('Account')"
        :active.sync="accEditPopupActive"
      >
        <div class="flex flex-wrap">
          <div class="w-full">
            <p>
              <vs-input
                v-model="accName"
                data-vv-validate-on="blur|keyup|change"
                v-validate="'required|max:20'"
                name="name"
                type="text"
                class="w-full"
                :label="$t('Name')"
              />
              <span class="text-danger text-sm">{{
                errors.first("name")
              }}</span>
            </p>
            <vs-divider />
            <vs-button
              size="medium"
              class="mb-4 md:mb-0"
              @click="updateAcc()"
              >{{ $t("Save") }}</vs-button
            >
          </div>
        </div>
      </vs-popup>

      <div class="vx-row">
        <div class="vx-col w-full">
          <vx-card :title="$t('Billing')" class="mb-base">
            <div>
              <h5>
                {{ $t("ChoosedPlan") }}
                {{ accPlanData.plan.name }}
              </h5>
            </div>

            <!--account-plan /-->
          </vx-card>
        </div>

        <div class="vx-col lg:w-1/2 w-full hidden">
          <vx-card :title="$t('Billing')" class="mb-base">
            <table>
              <tr>
                <td class="font-semibold">xxx</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">xxx</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">xxx</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">xxx</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">xxx</td>
                <td></td>
              </tr>
              <vs-button
                icon-pack="feather"
                icon="icon-edit"
                size="small"
                :to="{
                  name: 'app-user-edit',
                  params: { userId: $route.params.userId },
                }"
                >{{ $t("Edit") }}</vs-button
              >
            </table>
          </vx-card>
        </div>
      </div>

      <div class="vx-row hidden">
        <!--<div class="vx-col lg:w-1/2 w-full">-->
        <div class="vx-col w-full">
          <vx-card class="mb-base">
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="flex items-end px-3">
                  <feather-icon
                    svgClasses="w-6 h-6"
                    icon="DollarSignIcon"
                    class="mr-2"
                  />
                  <span class="font-medium text-lg leading-none">{{
                    $t("BillingHistory")
                  }}</span>
                </div>
                <vs-divider />
              </div>
            </div>

            <div class="block overflow-x-auto">
              <table class="w-full permissions-table">
                <tr>
                  <!--
                You can also use `Object.keys(Object.values(data_local.permissions)[0])` this logic if you consider,
                our data structure. You just have to loop over above variable to get table headers.
                Below we made it simple. So, everyone can understand.
              -->
                  <th
                    class="font-semibold text-base text-left px-3 py-2"
                    v-for="heading in [
                      'ID',
                      $t('Date'),
                      $t('Price'),
                      $t('Receipt'),
                    ]"
                    :key="heading"
                  >
                    {{ heading }}
                  </th>
                </tr>

                <tr v-for="(val, name) in accData.permissions" :key="name">
                  <td class="px-3 py-2">{{ name }}</td>
                  <td
                    v-for="(permission, name) in val"
                    class="px-3 py-2"
                    :key="name + permission"
                  >
                    <vs-checkbox
                      v-model="val[name]"
                      class="pointer-events-none"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </vx-card>
        </div>
      </div>

      <div class="vx-row">
        <!--<div class="vx-col lg:w-1/2 w-full">-->
        <div class="vx-col w-full" v-if="$acl.check('master')">
          <vx-card :title="$t('Delete') + ' ' + $t('Account')" class="mb-base">
            <p class="text-danger mb-4">
              <feather-icon
                icon="AlertCircleIcon"
                svgClasses="h-4 w-4 text-danger stroke-current"
              />
              {{ $t("MessageDelete") }} -
              <a
                href="javascript:void(0)"
                icon-pack="feather"
                icon="icon-edit"
                @click="accDeletePopupActive = true"
                >{{ $t("Delete") }}</a
              >
            </p>
          </vx-card>
          <vs-popup
            class="holamundo"
            :title="$t('Delete') + ' ' + $t('Account')"
            :active.sync="accDeletePopupActive"
          >
            <div class="flex flex-wrap">
              <div class="w-full">
                <div>{{ $t("MessageDeleteDetails") }}</div>
                <div>
                  <vs-input
                    name="coupon"
                    v-model="confirmDeleteText"
                    type="text"
                    class="w-full"
                    :label="$t('DeleteConfirmInput')"
                  />
                </div>
                <vs-divider />
                <vs-button
                  color="danger"
                  size="medium"
                  class="mb-4 md:mb-0"
                  @click="deleteAcc()"
                  >{{ $t("DeleteConfirmInputValue") }}</vs-button
                >
              </div>
            </div>
          </vs-popup>
        </div>
      </div>
    </div>
    <!--account-plan /-->
  </div>
</template>

<script>
//import vSelect from "vue-select";
//import AccountPlan from "./components/AccountPlan.vue";

export default {
  components: {
    //vSelect,
    //AccountPlan,
  },
  data() {
    return {
      newAccId: "",
      newAccPlanId: "",
      popupCreateAccActive: false,
      newAccName: "",
      newAccCompanyIndustry: "",

      confirmDeleteText: "",
      plansOptions: [],
      plansOptions2: [],
      userList: [],
      create: false,
      accEditPopupActive: false,
      accDeletePopupActive: false,
      companyIndustryOptions: [
        "Agências",
        "Automotivo",
        "Beleza",
        "Imóveis",
        "Produtos",
        "Restaurantes",
        "Serviços",
        "Startup",
        "Tecnologia",
        "Outros",
      ],
      accName: "",
      companyIndustry: "",
      accPlanId: this.$store.state.acc.current_acc.accPlan.plan.id,
      coupon: "",
      superlogicaId: 0,
      superlogicaToken: -1,
      showSuperlogicaIframe: false,
    };
  },
  computed: {
    appUrl() {
      return encodeURIComponent(document.location.href);
    },
    accData() {
      return this.$store.state.acc.current_acc;
    },
    accPlanData() {
      return this.$store.state.acc.current_acc.accPlan;
    },
    accWhitelabel() {
      return this.$store.state.acc.current_acc.whitelabel;
    },
    userAddress() {
      let str = "";
      for (var field in this.accData.location) {
        str += field + " ";
      }
      return str;
    },
  },
  watch: {
    superlogicaId(val) {
      this.superlogicaId = val;
    },
  },
  methods: {
    getSuperlogicaToken() {
      this.showSuperlogicaIframe = false;
      this.superlogicaToken = null;
      /*
      const thisIns = this;
      const acc = this.$store.state.acc.current_acc.id;
      this.$http({
        method: "GET",
        url: `/g/acc/plan/financial/sl-token`,
        params: {
          acc,
        },
      }).then((response) => {
        this.superlogicaToken = response.data.data.token;
        if (this.superlogicaToken)
          setTimeout(function () {
            thisIns.showSuperlogicaIframe = true;
            thisIns.$refs.iframeSuperlogica.style =
              "width: 100%; min-height: 400px";
          }, 3000);
      });*/
    },
    getFinancialDetails(acc = null, payload = null, create = false) {
      if (acc == null) acc = this.$store.state.acc.current_acc.id;
      this.$http({
        method: "GET",
        url: `/g/acc/plan/financial/${acc}`,
      }).then((response) => {
        this.superlogicaId = response.data.data.superlogicaIdCliente;
        if (create) this.createSignature(payload);
      });
    },
    createSignature(payload) {
      let payloadSignature = {
        ID_SACADO_SAC: parseInt(this.superlogicaId),
        FL_TRIAL_PLC: payload.trial > 0 ? 1 : 0,
        ID_PLANO_PLA: payload.idSuperlogica,
        ST_IDENTIFICADOREXTRA_PLC: payload.name,
      };
      this.$http({
        method: "POST",
        url: `/g/acc/plan/signature/${this.$store.state.acc.current_acc.id}`,
        data: {
          payloadSignature,
        },
      }).then(() => {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Ok"),
          text: this.$t("AccCreated"),
          iconPack: "feather",
          icon: "icon-success-circle",
          color: "success",
          position: "top-right",
        });
        this.popupCreateAccActive = false;
        this.$store.dispatch("acc/getAccounts");
        this.$store
          .dispatch("acc/setCurrentAccount", { acc: this.newAccId })
          .then(() => {
            this.$acl.change(this.$store.state.user.userRole);
            //window.location.replace('/');
          });
      });
    },
    deleteAcc() {
      if (this.confirmDeleteText === this.$t("DeleteConfirmInputValue")) {
        window.analytics.track(
          "Excluir Conta",
          {},
          { groupId: this.$store.state.acc.current_acc.id }
        );
        var payload = {
          id: this.$store.state.acc.current_acc.id,
        };

        this.$store
          .dispatch("acc/deleteAcc", payload)
          .then(() => {
            this.$vs.notify({
              time: 2500,
              title: this.$t("Ok"),
              text: this.$t("AccEdited"),
              iconPack: "feather",
              icon: "icon-success-circle",
              color: "success",
              position: "top-right",
            });
            this.accDeletePopupActive = false;
            this.$http
              .get("/g/acc/list")
              .then((response) => {
                if (response.data.data.length > 0) {
                  var idAcc = response.data.data[0]._id;
                  this.$store
                    .dispatch("acc/setCurrentAccount", { acc: idAcc })
                    .then(() => {
                      window.location.replace("/");
                    });
                }
              })
              .catch(() => {});
          })
          .catch((error) => {
            if (typeof error.request !== "undefined")
              if (typeof error.request.response !== "undefined")
                error.message = JSON.parse(error.request.response).message;

            this.$vs.notify({
              time: 2500,
              title: this.$t("Error"),
              text: error.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right",
            });
          });
      } else {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: this.$t("DeleteConfirmInputValueError"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
      }
    },
    updateAcc(payload) {
      this.$store
        .dispatch("acc/updateAcc", payload)
        .then(() => {
          this.$vs.notify({
            time: 2500,
            title: this.$t("Ok"),
            text: this.$t("AccEdited"),
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "success",
            position: "top-right",
          });
          this.accEditPopupActive = false;
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;

          this.$vs.notify({
            time: 2500,
            title: this.$t("Error"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
          });
        });
    },
    updateAccPlan() {
      var payload = {
        id: this.accPlanData.id,
        acc: this.$store.state.acc.current_acc.id,
        plan: this.accPlanId,
      };

      this.$store
        .dispatch("acc/updateAccPlan", payload)
        .then((response) => {
          this.$vs.notify({
            time: 2500,
            title: this.$t("Ok"),
            text: this.$t("AccPlanEdited"),
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "success",
            position: "top-right",
          });
          this.$store.commit("acc/SET_CURRENT_ACC_PLAN", response);
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;

          this.$vs.notify({
            time: 2500,
            title: this.$t("Error"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
          });
        });
    },
    formatDate(date) {
      var data = new Date(date),
        dia = data.getDate().toString(),
        diaF = dia.length == 1 ? "0" + dia : dia,
        mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = mes.length == 1 ? "0" + mes : mes,
        anoF = data.getFullYear();
      return diaF + "/" + mesF + "/" + anoF;
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm Delete`,
        text: `You are about to delete "${this.accData.username}"`,
        accept: this.deleteRecord,
        acceptText: "Delete",
      });
    },
    deleteRecord() {
      /* Below two lines are just for demo purpose */
      this.$router.push({ name: "app-user-list" });
      this.showDeleteSuccess();
    },
    showDeleteSuccess() {
      this.$vs.notify({
        color: "success",
        title: "User Deleted",
        text: "The selected user was successfully deleted",
        position: "top-right",
        time: 4000,
      });
    },
    getPlans() {
      var self = this;
      const payload = {
        product: "5e4c20ee2767d32b33eaa12e",
        whitelabel: this.accWhitelabel,
        notify: this.$vs.notify,
      };
      this.$store
        .dispatch("auth/fetchGetPlans", payload)
        .then((result) => {
          for (var i = 0; i < result.data.data.length; i++) {
            /*self.plansOptions.push({
              id: result.data.data[i]._id,
              label:
                result.data.data[i].title +
                " - R$ " +
                result.data.data[i].price +
                ",00 / mês",
            });*/
            self.plansOptions2.push({
              label:
                result.data.data[i].title +
                " - R$ " +
                result.data.data[i].price +
                ",00 / mês",
              value: result.data.data[i]._id,
              name: result.data.data[i].title,
              price: result.data.data[i].price,
              trial: result.data.data[i].trialDays,
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: this.$t("Error"),
            text: error.message || error.data[0].msg,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 4000,
          });
        });
    },
  },
  mounted() {
    this.getPlans();
    this.accName = this.accData.name;
    this.companyIndustry = this.accData.companyIndustry;
    //this.accPlanId = this.accPlanData.id;
    this.getFinancialDetails(null, null, false);
    this.getSuperlogicaToken();
  },
};
</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: 0.8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

// #account-info-col-1 {
//   // flex-grow: 1;
//   width: 30rem !important;
//   @media screen and (min-width:1200px) {
//     & {
//       flex-grow: unset !important;
//     }
//   }
// }

@media screen and (min-width: 1201px) and (max-width: 1211px),
  only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

  // #account-manage-buttons {
  //   width: 12rem !important;
  //   flex-direction: column;

  //   > button {
  //     margin-right: 0 !important;
  //     margin-bottom: 1rem;
  //   }
  // }
}
</style>
