<template>
  <div>
    <!-- Modal -->
    <div
      v-if="modalOpen"
      @click.self="closeModal"
      class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-9999999"
    >
      <div
        class="bg-white rounded-lg overflow-hidden shadow-lg max-w-lg w-full relative"
      >
        <!-- Close Button -->
        <a
          @click="closeModal"
          href="javascript:void(0)"
          class="absolute top-2 right-2 text-black px-2 py-2 bg-white text-2xl font-bold cursor-pointer bg-opacity-40 rounded-full"
        >
          &times;
        </a>
        <!-- Modal Content -->
        <div class="p-4">
          <video
            v-if="fileMimetype.includes('video')"
            controls="controls"
            height="300"
            class="w-full rounded-lg"
          >
            <source :src="src" />
            O seu navegador não suporta a tag vídeo
          </video>
          <img
            v-else-if="fileMimetype.includes('image')"
            :src="src"
            alt="Picture"
            class="w-full rounded-lg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InboxQuickMessageZoomModal",
  props: {
    src: {
      type: String,
    },
    fileMimetype: {
      type: String,
    },
    modalOpen: {
      type: Boolean,
    },
  },
  methods: {
    closeModal() {
      this.$emit("modalclose");
    },
  },
};
</script>
