<template>
  <div>
    <vs-card>
      <div slot="header">
        <h3>
          Integrações
        </h3>
      </div>
      <div
        class="w-full flex items-center justify-center h-80"
        :class="{'hidden': hasApiKey}"
      >
        <div class="text-center">
          <h3 class="text-quartz">Nenhuma integração criada ainda! <RocketIcon :size="18" /></h3>
          <p class="text-lg pt-3">Crie sua primeira integração para conectar a Duotalk com sistemas externos<br/>e automatizar seus processos. Clique no botão abaixo para começar!</p>
          <vs-button @click="popupAddIntegration = true" class="mt-5 mx-auto" icon-pack="feather" icon="icon-plus">Adicionar Integração</vs-button>
        </div>
      </div>
      <vs-table v-if="hasApiKey" stripe hoverFlat :data="integrationList" :noDataText="$t('EmptyTable')" class="mt-5 developer-table">
        <template slot="header">
          <vs-button
            type="line"
            target
            href="https://api.duotalk.io/api-docs/"
            icon-pack="feather"
            icon="icon-external-link"
            icon-after
            class="px-1 ml-1"
          >
            API DOC
          </vs-button>
          <vs-button
            icon-pack="feather"
            icon="icon-plus"
            class="mb-4 md:mb-0"
            @click="popupAddIntegration = true"
          >
            Adicionar Integração
          </vs-button>
        </template>
        <template slot="thead">
          <vs-th>{{ $t('Name') }}</vs-th>
          <vs-th>Chave</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr v-for="(tr, index) in data" :key="index">
            <vs-td>{{ tr.name }}</vs-td>
            <vs-td>{{ tr.key }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-card>
    <vs-popup
      title="Confirmar nova integração"
      :active.sync="popupAddIntegration"
    >
      <vs-row>
        <vs-col class="text-center">
          <feather-icon icon="AlertTriangleIcon" svgClasses="w-12 h-12 text-warning" />
        </vs-col>
        <vs-col class="mt-3">
          <h3 class="text-center">
            Após a confirmação, a chave anterior será<br/>desativada, e uma nova chave será criada.</h3>
        </vs-col>
        <vs-col vs-type="flex" vs-align="center" vs-justify="center" class="gap-x-4 mt-5">
          <vs-button
            color="danger"
            icon-pack="feather"
            icon="icon-x"
            icon-after
            @click="popupAddIntegration = false"
          >
            Cancelar
          </vs-button>
          <vs-button
            id="newIntegrationContainer"
            color="success"
            icon-pack="feather"
            icon="icon-check"
            icon-after
            class="vs-con-loading__container"
            @click="handleNewIntegration"
          >
            Confirmar
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
    <vs-popup
      title="API KEY"
      :active.sync="apiKeyPopup"
    >
      <vs-row>
        <vs-col vs-type="flex" vs-align="end" vs-justify="center" class="gap-x-4">
          <vs-input
            readonly
            label="API KEY"
            v-model="apiKey"
            class="w-full"
          />
          <vs-tooltip text="Copiar">
            <vs-button
              type="border"
              icon-pack="feather"
              icon="icon-copy"
              @click="copyApiKey"
            />
          </vs-tooltip>
        </vs-col>
        <vs-col>
          <span class="text-warning">Atenção, a chave da api é de visualização única, salve a chave em segurança.</span>
        </vs-col>
        <vs-col class="mt-5" vs-type="flex" vs-justify="center">
          <vs-button
            color="dark"
            type="border"
            icon-pack="feather"
            icon="icon-x"
            icon-after
            @click="apiKeyPopup = false"
          >
            Fechar
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
import RocketIcon from "@/components/svg/RocketIcon.vue";
import axios from "@/axios";

export default {
  name: "developer",
  components: {
    RocketIcon,
  },
  data() {
    return {
      integrationList: [
        {
          name: 'Chave de acesso',
          key: '********',
        }
      ],
      popupAddIntegration: false,
      apiKey: '',
      apiKeyPopup: false,
      hasApiKey: false,
    }
  },
  mounted() {
    this.verifyHasKey()
  },
  methods: {
    verifyHasKey() {
      axios.get('/p/webhook/v1/' + this.$store.state.acc.current_acc.id).then((response) => {
        this.hasApiKey = response.data.data
      })
    },
    handleNewIntegration() {
      this.$vs.loading({
        container: "#newIntegrationContainer",
        scale: 0.45,
      })

      axios.get('/p/webhook/v1/api-token/create?acc=' + this.$store.state.acc.current_acc.id).then((response) => {
        this.apiKey = response.data.data.apiKey
      }).finally(() => {
        this.$vs.loading.close()
        this.popupAddIntegration = false
        this.apiKeyPopup = true

        this.verifyHasKey()
      })
    },
    copyApiKey() {
      navigator.clipboard.writeText(this.apiKey).then().catch(err => {
        console.error('Erro ao copiar texto: ', err)
      })
    }
  }
}
</script>
<style lang="scss">
.developer-table .vs-table--header {
  margin-bottom: 1rem;
}
</style>
